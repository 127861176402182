import React from "react"
import styled from "styled-components"
import { render } from "react-dom"

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  overflow: hidden;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  @media only screen and (max-width: 992px) {
    iframe {
      padding: 0 0.5em;
    }
  }
`

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <VideoWrapper>
    <iframe
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </VideoWrapper>
)
export default Video
